import styled, { css } from 'styled-components'
import { Container, Grid, Typography } from '@material-ui/core'
import React, { ComponentProps } from 'react'

const removeMargin = css`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p,
  > figure,
  > ol,
  > ul,
  > img {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }
`

export const StyledFlexibleContentRow = styled(Container)`
  & + & {
    margin-block-start: 2.5em;
  }

  p {
    margin-block-start: 0;
  }
  ${removeMargin}
  .MuiGrid-item {
    ${props => props.theme.breakpoints.keys.map((key) => css`
      &[class*="MuiGrid-grid-${key}"]:last-child {
        ${removeMargin}
      }

      ${props => props.theme.breakpoints.up(key)} {
        ${[1, 2, 3, 4, 6].map((num) => css`
          &[class*="MuiGrid-grid-${key}-${12 / num}"] {
            ${removeMargin}
          }
        `)}
      }
    `)}
  }
`

export type FlexibleContentRowProps = ComponentProps<typeof StyledFlexibleContentRow> & {
  fullWidth?: boolean
}

export const FlexibleContentRow: React.FC<FlexibleContentRowProps> = ({ fullWidth, children, ...props }) => (
  <StyledFlexibleContentRow
    maxWidth={fullWidth ? false : 'lg'}
    disableGutters={fullWidth}
    component={'section'}
    {...props}
  >
    {fullWidth ? children : (
      <Grid container justifyContent={'center'}>
        <Typography variant="body2" component={Grid} item xs={12}>
          {children}
        </Typography>
      </Grid>
    )}
  </StyledFlexibleContentRow>
)